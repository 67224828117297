import React, {useCallback}  from "react"
import { Store } from "../../store/Store";

import { scroller } from 'react-scroll'

const SmoothScrollLink = ({ name, id, containerId }) => {
  const { dispatch } = Store();
  const duration = 500
  const delay = 50

     const smoothScrollToId = useCallback(() => {
      dispatch({ type: "deactivateSnapping" })
      scroller.scrollTo(id, {
        duration: duration,
        delay: delay,
        smooth: true,
        containerId: containerId,
        // offset: 50, // Scrolls to element + 50 pixels down the page
        // ...
      })
      setTimeout( ()=> {
        dispatch({ type: "activateSnapping" })
      },(duration + delay) )
    }, [id])

  return (
    <a onClick={smoothScrollToId}>
      {name}
    </a>
  )}

function onlyRenderIfLinkChanges(prevProps, nextProps) {
  if(prevProps.name !== nextProps.name) return false
  else return true
}

export default React.memo(SmoothScrollLink, onlyRenderIfLinkChanges)
